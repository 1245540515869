@import "../../../node_modules/bootstrap/scss/bootstrap";
$theme-colors: (
  "primary": #00a2ff
);
$mygreen : #97bf68;

/*! This special bang (!) comment is here to trick the `hash` Grunt task into working out-of-the-box, without any real CSS. You can delete this once you've imported ≥1 .less file as demonstrated above. */
#bienvenue h1, #bienvenue h2, #bienvenue h3, #bienvenue h4 {
    text-shadow: 0.5px 0 0 #adb5bd, 0 -0.5px 0 #adb5bd, 0 0.5px 0 #adb5bd, -0.5px 0 0 #adb5bd;
}
body{
  @font-face {
    font-family: "Kollektif";
    src: url("./fonts/Kollektif.ttf");
  };
  font-family: "Kollektif";
  color:$mygreen;
  font-size:1.2rem;
}

button > *, .navbar img{
  pointer-events: none!important;
}
#bienvenue{
  background:linear-gradient(#C6EAFF,#f4fbff);
  box-shadow: 1px 1px 999px 1111px #c6eaff;
  padding:0;
}
#lieux{
	box-shadow: 1px 1px 999px 1111px #c6eaff;
}
#bienvenue .cloud{
  background: top/contain no-repeat url("../images/white-cloud.png") ;
}
#bienvenue h1{
  padding-top:50px;
}
#bienvenue h1, #bienvenue h3, #bienvenue p:not(.last){
  padding-bottom:50px;
}
.footer-wave-banner{
  background: 95% 0/11% no-repeat url("../images/logo_site.svg");
}
.card-body.lieu-description{
	max-height:200px;
	min-height:200px;
}
.card-header{
	height:200px;
}
.card-header a{
	text-decoration:underline;
	text-underline-offset: 0.25rem;
}
p {
white-space: pre-wrap;
}
.rounded-bottom {
    border-bottom-right-radius: 0.6rem!important;
    border-bottom-left-radius: 0.6rem!important;
}
.footer-wave-banner img {
  width: 100%;
}
@media only screen and (min-width: 500px) {
.grid{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 20px;
}
}
@media only screen and (min-width: 767px) {
.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}
}
@media (max-width: 576px) {
  body{
    font-size: 1.4rem;
  }
  #bienvenue ul{
    list-style:none;
    margin-right:15px;
  }
  div#site-footer a::before {
    content: " ";
    display: block;
    clear: both;
    margin-bottom:5px;
  }
  #site-header{
    font-size: 0.8rem;
    padding-top:0;padding-bottom:0;
  }
  #gogocarto iframe{
    height:500px;
  }
}
